#root {
  background-image: url('https://cdn.sensei3d.com/static/media/bg.fa4ff5df.jpg');
  height: 100%;
  width: 100%;
}

#root::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.App {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  font-family: Roboto,sans-serif;
  font-weight: 500;
  font-size: 15px;
}