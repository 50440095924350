body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body,
html {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 15px;
    font-style: normal;
    overflow: hidden;
}

html * {
    box-sizing: border-box;
}

.flex-row .nav-item {
    display: flex;
}

.flex-row .nav-item .nav-link {
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    padding: 15px 0 5px;
}

.flex-row .nav-item .nav-link::before {
    height: 2px;
    width: 100%;
    bottom: -1px;
    left: 0;
}

.flex-row .nav-item .nav-link.active {
    background: white;
    color: #eb7324;
}

.flex-row .nav-item .nav-link.active::before {
    background: #eb7324;
}

.flex-row .nav-item .nav-link.active path {
    fill: #eb7324;
}

.flex-row .nav-item .nav-link svg {
    margin-right: 5px;
}

.checkbox--border .MuiSvgIcon-root {
    border: 2px solid #eb7324;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

@media (max-width: 991px) {
    body {
        overflow: auto;
    }

    .build-menu-nav {
        display: flex;
        align-items: center;
        height: 100%;
        background-color: #fff;
    }

    .build-menu-nav .nav-item {
        width: 100%;
        flex: 1 1;
        height: 100%;
    }
    
    .build-menu-nav .nav-link {
        flex-direction: row !important;
        justify-content: center;
        width: auto;
        height: 100%;
        padding: 15px 20px;
        border-top: 1px solid #adadad;
        border-right: 1px solid #adadad;
        font-size: 11px;
        border-radius: 0;
    }

    .build-menu-nav .nav-link::before {
        height: 2px !important;
        width: 100% !important;
        bottom: initial !important;
        top: 0 !important;
        background-color: #eb7324 !important;
    }

    .build-menu-nav .nav-link svg {
        display: none;
    }

    .ScrollbarsCustom-Wrapper, .ScrollbarsCustom-Scroller {
        position: static !important;
        overflow: auto !important;
    }

    .ScrollbarsCustom-Content {
        display: flex;
        white-space: normal;
        overflow-x: auto;
        width: auto;
        max-width: none;
        flex-wrap: nowrap;
        position: relative;
        background: #fff;
        padding: 1px 0!important;
    }
}